import { PushNotifications } from '@capacitor/push-notifications'

export default {
  methods: {
    initPush (requestPermissions = false) {
      if (requestPermissions) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register()
          } else {
            // Show some error
            console.error('Push Perm Request failed')
          }
        })
      }

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', token => {
        console.log('Push registration success', token)
        const tkn = token.value
        const pushtokens = this.$root.user.pushtokens || []
        if (pushtokens.indexOf(tkn) === -1) {
          pushtokens.push(tkn)
          this.$fb.db.doc('users/' + this.$root.userId).update({
            pushtokens
          })
        }
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', error => {
        console.error('Push registration error', error)
      })

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push received (in app)', notification)
        this.showNotifications = true
      })

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push action performed', notification)
        const notificationData = JSON.parse(notification.notification.data.notification)
        this.openNotification(notificationData)
      })
    }
  }
}
