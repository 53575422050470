<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <div v-if="drawerViews && drawerViews.length > 0">
        <v-list dense nav>
          <v-list-item-group :value="viewId" color="primary">
            <v-list-item
              v-for="item in drawerViews"
              :key="item.id"
              :value="item.id"
              @click="viewId = item.id"
            >
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider/>
      </div>

      <div>
        <v-list dense nav>
          <v-list-item-group :value="viewId" color="primary">
            <v-list-item
              v-for="item in userViews"
              :key="item.id"
              :value="item.id"
              @click="openView(item)"
            >
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div v-if="adminViews && adminViews.length > 0">
        <v-divider/>
        <v-list dense nav>
          <v-subheader>Administration</v-subheader>
          <v-list-item-group :value="viewId" color="primary">
            <v-list-item
              v-for="item in adminViews"
              :key="item.id"
              :value="item.id"
              @click="item.open ? openWindow(item.open) : viewId = item.id"
            >
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item @click="$root.signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-app-bar-nav-icon v-if="$route.name === 'Home'" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn v-else icon @click="navigateBack"><v-icon dark>mdi-arrow-left</v-icon></v-btn> -->

      <v-toolbar-title>{{ view ? view.name : '' }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-menu
        v-else-if="view.menu"
        bottom
        offset-y
        left
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(item, i) in view.menu"
            :key="i"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-btn v-if="viewEditable" icon @click="edit">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-menu
        v-model="showNotifications"
        bottom
        offset-y
        left
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-badge
              :value="notificationsUnreadCount > 0"
              :content="notificationsUnreadCount"
              color="secondary"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(notification, i) in notifications"
            :key="i"
            @click="openNotification(notification)"
          >
            <v-icon v-if="notification.type.split('-')[0] === 'message'" class="pr-2">{{ !notification.read ? 'mdi-chat' : 'mdi-chat-outline' }}</v-icon>
            <v-icon v-else class="pr-2">{{ !notification.read ? 'mdi-email' : 'mdi-email-open-outline' }}</v-icon>
            <v-list-item-title>{{ notification.from || $t(notification.type.replace(/-/g, '_')) }}</v-list-item-title>
            <v-list-item-subtitle>{{ notification.text }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider/>
          <v-list-item :disabled="notificationsUnreadCount === 0" @click="clearNotifications">
            <v-icon class="pr-2">mdi-notification-clear-all</v-icon>
            <v-list-item-title>Alle als gelesen markieren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showNotificationSettingsDlg = true">
            <v-icon class="pr-2">mdi-cog-outline</v-icon>
            <v-list-item-title>Benachrichtigungseinstellungen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-for="item in topViews"
        :key="item.id"
        :value="item.id"
        icon
        @click="openView(item)"
      >
        <v-icon>mdi-{{ item.icon }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-snackbar v-model="showSnack" top :color="snack ? snack.color : null">
      {{ snack ? snack.text : null }}
    </v-snackbar>

    <v-main>
      <!--
        keep-alive causes HMR issues (https://github.com/vuejs/vue-loader/issues/1332);
        also we might actually be happy with the data refresh on view changes..
      -->
      <!-- <keep-alive> -->
        <component v-if="view" :key="view.id" ref="viewCmp" :is="getCmp(view.type)" :config="view" :itemId.sync="viewItemId" :class="{ 'container-wide': view.admin }" @open="openDlg" @openView="openView"/>
      <!-- </keep-alive> -->
    </v-main>

    <v-dialog
      :value="dlgView !== null"
      fullscreen
      hide-overlay
      persistent
    >
      <div
        v-if="dlgView !== null"
        class="d-flex flex-column"
        style="height: 100%;"
      >
        <v-toolbar color="primary" dark style="z-index: 1;">
          <v-btn icon dark @click="dlgView = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ dlgView.name || '' }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="dlgView = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable" style="flex: 1;">
          <component
            ref="dlgCmp"
            :is="getCmp(dlgView.type)"
            :config="dlgView"
            style="height: 100%"
            @close="dlgView = null"
          />
        </div>
      </div>
    </v-dialog>

    <v-bottom-navigation :value="viewId" app grow color="primary">
      <v-btn v-for="vw in views" :key="vw.id" :value="vw.id" :style="vw.bottom ? '' : 'display: none'" @click="viewId = vw.id">
        <span>{{ vw.name }}</span>
        <v-icon>mdi-{{ vw.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-sheet
      :value="!!$root.displayUserId"
      width="100%"
      @input="$root.displayUserId = null"
    >
      <v-sheet class="pa-5">
        <user-profile v-if="displayUser" :user="displayUser" @open="openDlg" @openView="openView"/>
      </v-sheet>
    </v-bottom-sheet>

    <notification-settings-dlg v-if="showNotificationSettingsDlg" @close="showNotificationSettingsDlg = false"/>

    <slot></slot>
  </v-app>
</template>

<script>
import { App as CapacitorApp } from '@capacitor/app'
import PushMixin from '@/mixins/PushMixin'

const VIEWS = [
  { id: 'profile', type: 'profile', name: 'Mein Profil', icon: 'account-circle', user: true, dlg: true },
  { id: 'tags', type: 'tags', name: 'Meine Themen', icon: 'pound-box', user: true, dlg: true },

  { id: 'moderation', type: 'ModerationAdmin', name: 'Moderation', icon: 'account-supervisor-circle', admin: true, moderator: true },
  { id: 'print', open: '/print', name: 'Druckassistent', icon: 'printer', admin: true },
  { id: 'usersadmin', type: 'UsersAdmin', name: 'Benutzerverwaltung', icon: 'account-multiple', admin: true },
  { id: 'groups', type: 'GroupsAdmin', name: 'Gruppen', icon: 'shape', admin: true },
  { id: 'viewsadmin', type: 'ViewsAdmin', name: 'Konfiguration', icon: 'view-quilt', admin: true },
  { id: 'wastebin', type: 'WastebinAdmin', name: 'Papierkorb', icon: 'delete', admin: true },

  // { id: 'tmp' }
]

export default {
  name: 'Home',
  mixins: [PushMixin],
  components: {
    // views
    ChatsView: () => import('@/views/ChatsView'),
    EventsView: () => import('@/views/EventsView'),
    IframeView: () => import('@/views/IframeView'),
    PlayerView: () => import('@/views/PlayerView'),
    PostsView: () => import('@/views/PostsView'),
    ProfileView: () => import('@/views/ProfileView'),
    StaticView: () => import('@/views/StaticView'),
    TagsView: () => import('@/views/TagsView'),
    UsersView: () => import('@/views/UsersView'),
    UsersquizView: () => import('@/views/UsersquizView'),
    // admin
    GroupsAdmin: () => import('@/views/GroupsAdmin'),
    ModerationAdmin: () => import('@/views/ModerationAdmin'),
    ViewsAdmin: () => import('@/views/ViewsAdmin'),
    UsersAdmin: () => import('@/views/UsersAdmin'),
    WastebinAdmin: () => import('@/views/WastebinAdmin'),
    // components
    NotificationSettingsDlg: () => import('@/components/base/NotificationSettingsDlg'),
    UserProfile: () => import('@/components/base/UserProfile')
  },
  data () {
    return {
      viewId: null, // the current viewId, no matter what
      viewItemId: null, // the current viewItemId (posts-post, events-event, chats-chat)
      dlgView: null, // dlg view, not in $cfg
      drawer: null,
      showNotifications: false,
      showNotificationSettingsDlg: false,
      showSnack: false
    }
  },
  computed: {
    views () {
      return [
        ...VIEWS,
        ...this.$cfg.views
      ]
        .filter(v => !v.admin || this.$root.isAdmin !== false) // racing.. if isAdmin is not yet set, accept
        .filter(v => this.$cfg[v.id] !== false) // views can be disabled in $cfg, for example 'groups': false
        .map(v => ({ ...v, ...this.$root.views[v.id] })) // merge with db view cfg
        .sort((a, b) => (a.nr || 0) - (b.nr || 0))
    },
    drawerViews () {
      return this.views.filter(v => v.drawer)
    },
    userViews () {
      return this.views.filter(v => v.user)
    },
    adminViews () {
      if (!this.$root.isAdmin && !this.$root.isModerator) return null
      return this.views.filter(v => (v.admin && this.$root.isAdmin || v.moderator && this.$root.isModerator) && (!v.if || this.$cfg[v.if]))
    },
    topViews () {
      return this.views.filter(v => v.top)
    },
    bottomViews () {
      return this.views.filter(v => v.bottom)
    },
    view () {
      if (!this.viewId) return null
      return this.views.find(v => v.id === this.viewId)
    },
    viewEditable () {
      return this.view?.editable &&
        // if view is not protected or current user is moderator
        (this.$root.isAdmin || !this.view.protected || this.view.moderators?.includes(this.$root.userId))
    },
    notifications () {
      return this.$root.notifications || []
    },
    notificationsUnreadCount () {
      return this.notifications.filter(n => !n.read).length
    },
    displayUser () {
      if (!this.$root.displayUserId) return null
      return { id: this.$root.displayUserId, ...this.$root.users[this.$root.displayUserId] }
    },
    snack () {
      return this.$root.snack
    }
  },
  methods: {
    getCmp (type) {
      // if type is lowercase, start with upper and append 'View', otherwise keep as is
      const vup = type.charAt(0).toUpperCase()
      if (vup !== type.charAt(0)) {
        return vup + type.slice(1) + 'View'
      } else {
        return type
      } 
    },
    openView (view) {
      this.$root.displayUserId = null
      this.$nextTick(() => { // very important! this allows the bottom-sheet to disappear (and remove the html.overlfow-y-hidden class) and then the dlg to appear and add it again - otherwise we had racing conditions adding and removing this class (scrollbar issues)!
        if (view.dlg) {
          this.openDlg(view)
        } else {
          this.viewId = view.id
          if (view.itemId) {
            this.viewItemId = view.itemId
          }
        }
      })
    },
    openDlg (view) {
      this.$root.displayUserId = null
      this.$nextTick(() => { // very important! this allows the bottom-sheet to disappear (and remove the html.overlfow-y-hidden class) and then the dlg to appear and add it again - otherwise we had racing conditions adding and removing this class (scrollbar issues)!
        this.dlgView = view
      })
    },
    openNotification (notification) {
      if (notification.type === 'new-post-review') {
        this.viewId = 'moderation'
        this.viewItemId = notification.postId
      } else if (notification.viewId) { // only check for backwards-compatibility
        this.viewId = notification.viewId
        this.viewItemId = notification.postId || notification.chatId || notification.eventId || null
      }

      this.showNotifications = false

      // mark notification as read
      if (!notification.read) {
        this.$fb.db.doc('users/' + this.$root.userId + '/notifications/' + notification.id).update({
          read: true
        })
      }
    },
    clearNotifications () {
      const batch = this.$fb.db.batch()
      this.notifications.forEach(notification => {
        // mark notification as read
        if (!notification.read) {
          batch.update(this.$fb.db.doc('users/' + this.$root.userId + '/notifications/' + notification.id), {
            read: true
          })
        }
      })
      batch.commit()
      this.showNotifications = false
    },
    openWindow (path) {
      window.open(path, '_blank')
    },
    edit () {
      this.$refs.viewCmp.edit()
    }
  },
  watch: {
    viewId (viewId) {
      if (viewId) {
        // close dlg (if open) on nav change
        this.dlgView = null
        // temp or forever? useful for dev reloads ;)
        localStorage.setItem('viewId', viewId)
        // also close drawer (on mobile)
        if (this.$vuetify.breakpoint.mobile) {
          this.drawer = false
        }
      }
    },
    view (view) {
      // handle inaccessible views (no longer admin, cfg change, ..)
      if (view === null) {
        this.viewId = this.bottomViews[0].id
      }
    },
    snack (snack) {
      if (snack) {
        this.showSnack = true
      }
    },
    showSnack (showSnack) {
      if (!showSnack) {
        this.$root.snack = null
      }
    }
  },
  created () {
    // start with last view or first bottom view
    const lastViewId = localStorage.getItem('viewId')
    this.viewId = lastViewId && this.views.find(v => v.id === lastViewId)
      ? lastViewId
      : this.bottomViews[0].id

    // android hardware back button handler
    CapacitorApp.addListener('backButton', () => {
      let handled = false

      if (this.dlgView) {
        if (this.$refs.dlgCmp?.back !== undefined) {
          handled = this.$refs.dlgCmp.back()
        }
        if (!handled) {
          this.dlgView = null
          handled = true
        }
      } else if (this.$refs.viewCmp) {
        if (this.$refs.viewCmp.back !== undefined) {
          handled = this.$refs.viewCmp.back()
        }
      }

      // if not handled, close app
      if (!handled) {
        CapacitorApp.exitApp()
      }
    })

    // register for push notifications (only on native)
    if (this.$root.native) {
      this.initPush(true)
    }
  }
}
</script>
